import React from 'react';
// import logo from './spin.js';
import './App.css';
// import UpcomingGrid from './UpcomingGrid.js';
// import RecentGrid from './RecentGrid.js';
import VideoApp from './Video.js';
import spliceText from './splice_without_dot_png.png';

import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
// import ReactTwitchEmbedVideo from 'react-twitch-embed-video';
// import ReactFlowPlayer from 'react-flow-player';
// import VideoPlayer from 'react-video-js-player';

function App() {
  return (
    <div className='App'>
      <header className='App-header'>
        {/* <Main /> */}
        <Router>
          <Switch>
            <Route path='/' exact component={() => <Main />} />
            <Route path='/splice' exact component={() => <Splice />} />
          </Switch>
        </Router>
      </header>
    </div>
  );
}

function Main() {
  return (
    <div
      style={{
        width: '100vw',
        display: 'flex',
      }}
    >
      {/* {logo} */}
      <div className='col1' style={{ width: '20vw' }}>
        <img src={spliceText} id='splice-text' alt='Splice' />
      </div>
      <div style={{ width: '60vw', textAlign: 'left' }}>
        <h1>today's party - SPLICE</h1>
        <div style={{ textAlign: 'left' }}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <div>
              <VideoApp width='720' />
            </div>
          </div>

          <p>
            We acknowledge that SPLICE takes place on stolen land belonging to
            the Gadigal peoples of the Eora nation.
          </p>
          <p>
            <strong>ATHLETICA X HONEY POINT X SUCROSE PRESENT SPLICE </strong>
          </p>
          <p>
            SPLICE is a meeting of collectives, sounds, seasons to raise funds
            for Chalo HQ charities (Human Rights Law Network and Zindagi Trust)
            and the Aboriginal Legal Service.{' '}
            <a href='https://events.humanitix.com/splice-athletica-x-honey-point-x-sucrose-fundraiser'>
              donate now if you can
            </a>
          </p>
          <p></p>
        </div>
      </div>
      <div
        className='col3'
        style={{
          width: '20vw',
          paddingTop: '10vw',
        }}
      >
        <p
          style={{
            textAlign: 'left',
            fontFamily: 'Archivo Black',
            textTransform: 'uppercase',
          }}
        >
          Feat <br />
          <span className='deepa'>Deepa & Dame</span> <br />{' '}
          <span className='deepa'>Jitwam</span>
          <br />
          <span className='deepa'>Isa</span>
          <br /> <span className='deepa'>Rakish</span>
          <br /> <span className='deepa'>Sucrose Sound</span>
          <br /> <span className='deepa'> x-fling </span>
          <br />
        </p>
      </div>
    </div>
  );
}

// function Splice() {
//   return (
//     <div
//       style={{
//         // backgroundImage: `url(${spliceUrl})`,
//         backgroundColor: 'white',
//         height: '100vh',
//         width: '100vw',
//         color: 'rgb(59,67,213)',
//         fontFamily: 'Archivo Black',
//       }}
//     >
//       <div>
//         <h1>This is the splice page</h1>
//       </div>
//       <div
//         style={{
//           display: 'flex',
//           alignItems: 'space-around',
//           justifyContent: 'space-around',
//         }}
//       >
//         <div>
//           <img src={spliceText} id='splice-text' alt='Splice' />
//         </div>
//         <VideoApp />
//       </div>
//     </div>
//   );
// }

function Splice() {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        background: 'rgb(220,220,220)',
        width: 1440,
        height: 800,
      }}
    >
      <div className='col1' style={{ width: '15%' }}>
        <img src={spliceText} id='splice-text' alt='Splice' />
      </div>
      <div className='col2' style={{ width: '70%', height: '100%' }}>
        <div className='row1' style={{ height: '5%' }}></div>
        <div className='row2' style={{ textAlign: 'start' }}>
          <p>
            We acknowledge that SPLICE takes place on stolen land belonging to
            the Gadigal peoples of the Eora nation.{' '}
          </p>
          <p>
            ATHLETICA X HONEY POINT X SUCROSE PRESENT SPLICE SPLICE is a meeting
            of collectives, sounds, seasons to raise funds for Chalo HQ
            charities (Human Rights Law Network and Zindagi Trust) and the
            Aboriginal Legal Service.
          </p>
        </div>
        <div className='row3' style={{ height: '5%' }}></div>
        <div
          className='row4'
          style={{
            height: '65%',
            textAlign: 'center',
            display: 'flex',
            // justifyContent: 'space-around',
          }}
        >
          <VideoApp width={800} />
        </div>
        <div className='row5' style={{ height: '5%' }}></div>
      </div>
      <div className='col3' style={{ width: '15%' }}>
        Feat Deepa & Dame, Jitwam, Isa, Rakish, Sucrose Sound x-fling <br />{' '}
        <br />
        <a href='https://events.humanitix.com/splice-athletica-x-honey-point-x-sucrose-fundraiser'>
          donate now if you can
        </a>
      </div>
    </div>
  );
}

export default App;
