import React, { Component } from 'react';
import VideoPlayer from 'react-video-js-player';

// function VideoApp() {
//   // player = {};
//   // state = {
//   //   video: {
//   //     src: 'https://livestream.kickonline.co/hls/multi.m3u8',
//   //     poster: 'http://www.example.com/path/to/video_poster.jpg',
//   //   },
//   // };

//   // function onPlayerReady(player) {
//   //   console.log('Player is ready: ', player);
//   //   this.player = player;
//   // }

//   // function onVideoPlay(duration) {
//   //   console.log('Video played at: ', duration);
//   // }

//   // function onVideoPause(duration) {
//   //   console.log('Video paused at: ', duration);
//   // }

//   // function onVideoTimeUpdate(duration) {
//   //   console.log('Time updated: ', duration);
//   // }

//   // function onVideoSeeking(duration) {
//   //   console.log('Video seeking: ', duration);
//   // }

//   // function onVideoSeeked(from, to) {
//   //   console.log(`Video seeked from ${from} to ${to}`);
//   // }

//   // function onVideoEnd() {
//   //   console.log('Video ended');
//   // }

//   const [width, setWidth] = React.useState(window.innerWidth);
//   React.useEffect(() => {
//     /* Inside of a "useEffect" hook add an event listener that updates
//        the "width" state variable when the window size changes */
//     window.addEventListener('resize', () => setWidth(window.innerWidth));

//     /* passing an empty array as the dependencies of the effect will cause this
//        effect to only run when the component mounts, and not each time it updates.
//        We only want the listener to be added once */
//   }, []);

//   return (
//     <div style={{}}>
//       <VideoPlayer
//         controls={true}
//         // src={this.state.video.src}
//         // poster={this.state.video.poster}
//         // onReady={this.onPlayerReady.bind(this)}
//         // onPlay={this.onVideoPlay.bind(this)}
//         // onPause={this.onVideoPause.bind(this)}
//         // onTimeUpdate={this.onVideoTimeUpdate.bind(this)}
//         // onSeeking={this.onVideoSeeking.bind(this)}
//         // onSeeked={this.onVideoSeeked.bind(this)}
//         // onEnd={this.onVideoEnd.bind(this)}
//         width={0.6 * width}
//         height='auto'
//       />
//     </div>
//   );
// }

class VideoApp extends Component {
  player = { fluid: true };
  state = {
    video: {
      src: 'https://livestream.kickonline.co/hls/multi.m3u8',
      // poster: 'http://www.example.com/path/to/video_poster.jpg',
    },
  };

  onPlayerReady(player) {
    console.log('Player is ready: ', player);
    this.player = player;
  }

  onVideoPlay(duration) {
    console.log('Video played at: ', duration);
  }

  onVideoPause(duration) {
    console.log('Video paused at: ', duration);
  }

  onVideoTimeUpdate(duration) {
    console.log('Time updated: ', duration);
  }

  onVideoSeeking(duration) {
    console.log('Video seeking: ', duration);
  }

  onVideoSeeked(from, to) {
    console.log(`Video seeked from ${from} to ${to}`);
  }

  onVideoEnd() {
    console.log('Video ended');
  }

  render() {
    return (
      <div style={{}}>
        <VideoPlayer
          controls={true}
          src={this.state.video.src}
          poster={this.state.video.poster}
          onReady={this.onPlayerReady.bind(this)}
          onPlay={this.onVideoPlay.bind(this)}
          onPause={this.onVideoPause.bind(this)}
          onTimeUpdate={this.onVideoTimeUpdate.bind(this)}
          onSeeking={this.onVideoSeeking.bind(this)}
          onSeeked={this.onVideoSeeked.bind(this)}
          onEnd={this.onVideoEnd.bind(this)}
          width={this.props.width}
        />
      </div>
    );
  }
}
export default VideoApp;
